import React from 'react';
import ProductInspiration from './ProductInspiration';
import {InsporationContextProvider} from "./InspirationContext";
import loadable from "@loadable/component";

const Breadcrumbs = loadable(() => import('../../../components/Category/Breadcrumbs/Breadcrumbs'));
const Products = loadable(() => import('../../../components/Category/Products/Products'), {ssr: false});


const Inspiration = ({product}) => {

    const {
        sku,
        name,
        categories,
        description,
        items,
    } = product || {};

    const breadcrumbs = categories && categories[categories.length - 1];
    return (
        <section className={'inspiration'}>
            <Breadcrumbs routeData={breadcrumbs} name={name}/>
            <div className="inspiration-wrapper">
                <InsporationContextProvider product={product}>
                    <ProductInspiration/>
                </InsporationContextProvider>
            </div>
            <h1 className={'product__name'}>{name}</h1>

            {description &&
                <div className={'product__description'} dangerouslySetInnerHTML={{__html: description.html}}/>}

            <div className={'item-list'}>
                {items && !!items.length && (
                    <div className="product__related">
                        <Products items={items.map((item) => item.product)}/>
                    </div>
                )}
            </div>

        </section>
    );
};

export default Inspiration;
