import React, {useEffect, useRef, useState} from 'react';
import {useUserContext} from "../../../context/UserContext";
import AdminToolbar from "./AdminToolbar";
import {FaEye, FaTools} from "react-icons/fa";
import {useMutation} from "react-apollo";
import {POINTS_COORDINATES} from "../../../routes/routeQuery";
import {useInspirationContext} from "./InspirationContext";


const Admin = () => {
    const {product, items, setItems} = useInspirationContext();
    const [activeSku, setActiveSku] = useState(null);
    const [editMode, setEditMode] = useState(false);
    const [saveStatus, setSaveStatus] = useState(false)
    const [markerEnabled, setMarkerEnabled] = useState(false)
    const [addPoints, {loading, error, data}] = useMutation(POINTS_COORDINATES);
    const refActiveSku = useRef(null);


    useEffect(()=>{

    },[markerEnabled])

    useEffect(() => {
            if (markerEnabled) {
                const handleSetPointEvent = (event) => {
                    handleSetMarker(event.detail)
                }

                document.addEventListener('setPointEvent', handleSetPointEvent);
                return () => {
                    document.removeEventListener('setPointEvent', handleSetPointEvent);
                }
            }
        }
        , [markerEnabled])

    useEffect(() => {
        if (!loading && saveStatus) {
            setSaveStatus(false)
        }
    }, [loading])

    const handleSetMarker = ({x, y}) => {
        if (refActiveSku.current) {
            setItems(
                items.map((item) => {
                    if (item.product.sku == refActiveSku.current) {
                        item.point.left = x
                        item.point.top = y
                        item.point.disabled = false
                    }
                    return item
                })
            )
            setSaveStatus(true)
        }
    }

    const removePointBySku = (sku) => {
        setItems(
            items.map((item) => {
                if (item.product.sku == sku) {
                    item.point.disabled = true
                }
                return item
            })
        )
        setSaveStatus(true)
    }

    const savePoints = () => {
        const out = [];
        items.forEach((item) => {
            if (!Boolean(item.point.disabled)) {
                out.push(item.point)
            }
        })

        addPoints({
            variables: {
                sku: product.sku,
                points_coordinates: JSON.stringify(out)
            }
        })

    }

    const toolbarSelectItemHandle = (sku) => {
        refActiveSku.current = sku
        setActiveSku(sku)
        setMarkerEnabled(false)
    }

    const drawItem = item => {
        let classes = [
            'item'
        ]
        if (item.point.disabled == true) {
            classes.push('disabled_item')
        }
        if (item.product.sku == activeSku) {
            classes.push('active')
        }
        return (
            <div key={item.product.sku}
                 onClick={() => toolbarSelectItemHandle(item.product.sku)}
                 className={classes.join(' ')}>
                <div>{item.product.sku}</div>
                {!item.point.disabled &&
                    <FaEye className={`${item.point.visible === true ? 'visible' : 'notvisible'}`} size={16}/>}
            </div>
        );
    };

    return (
        <div className="admin-toolbar">
            <FaTools size={25} onClick={() => setEditMode(!editMode)}/>
            {editMode &&
                <>
                <div className="admin-toolbar-items">
                    {items.map(item => {
                        return drawItem(item);
                    })}

                </div>
                    {activeSku && <AdminToolbar item={items.find(item => item.product.sku == activeSku)}
                                                removePoint={removePointBySku}
                                                markerEnabled={markerEnabled}
                                                setMarkerEnabled={() => setMarkerEnabled(!markerEnabled)}

                    />}
                    {saveStatus && <div className={'save'} onClick={savePoints}>Save Points</div>}
                </>
            }
        </div>
    )
}
export const AdminDecorator = (WrappedComponent) => {

    return (props) => {
        const {user} = useUserContext();


        if (user && user.is_admin) {
            return <WrappedComponent {...props} adminMode={true}><Admin {...props}/></WrappedComponent>;
        } else {
            return <WrappedComponent {...props} />;
        }
    }

}

