import React from "react";
import loadable from '@loadable/component';
import readConfig from '../../utils/readClientConfiguration';
import productionConfiguration from '../../../config/default.json';

const Helmet = loadable(() => import('react-helmet'));
const currencyCode = readConfig(productionConfiguration, 'currencyCode').toUpperCase();
function prepareString(name){
  return name.replace(/\"/g, '\'').replace(/\\/g, '/')

}
export default function StructuralDataProduct({product}) {

  const {
    sku,
    name,
    gallery,
    description,
    is_saleable,
    price_range
  } = product || {};

  let final_price = price_range.minimum_price.final_price.value;

  switch (product.__typename) {
    case "GroupedProduct":
      final_price = product.items[0].product.price_range.minimum_price.final_price.value
      break;
  }

  const cleanDescription = description.html.replace(/<br>/g, ' ').replace(/(<([^>]+)>)/gi, ' ');

  const firstImage = gallery && gallery.length && gallery.reduce((prev, curr) => (prev.position < curr.position ? prev : curr));
  const firstImageUrl =
      firstImage && (firstImage.large_image_url || firstImage.medium_image_url || firstImage.small_imgage_url);

  return <Helmet>
    <script type="application/ld+json">{`
  {
    "@context": "https://schema.org",
    "@type": "Product",
    "description": "${prepareString(cleanDescription)}",
    "name": "${prepareString(name)}",
    "image": "${firstImageUrl}",
    "sku" : "${sku}",
    "offers": {
      "@type": "Offer",
      "availability": "${is_saleable ? "https://schema.org/InStock" : "https://schema.org/OutOfStock"}",
      "price": "${final_price}",
      "priceCurrency": "${currencyCode}"
    }
  }
  `}</script> 
</Helmet>
}
