import React from 'react';
import ProductItem from "../../../components/Category/ProductItem/ProductItem";
import {normalizeProductItem} from "../../../components/Category/Products/Products";

const ItemView = ({item, style}) => {

    const product = normalizeProductItem(item.product)
    return (
        <div className={'item-view'} style={style}>
            <ProductItem key={product.sku} product={product} isCategory={false} isQuarticon={false}/>
        </div>
    )
}

export default ItemView