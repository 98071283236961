import {useEffect, useState} from 'react';
import {useLazyQuery} from "react-apollo";
import {GET_PRODUCT_SLIDER} from "../queries/misc";

const useRelatedProducts = (relatedSKUs) => {
    const [products, setProducts] = useState([]);
    const [query, {data, loading}] = useLazyQuery(GET_PRODUCT_SLIDER, {
        onCompleted: (data) => {
            setProducts(data.productsByCondition.items);
        }
    });


    useEffect(() => {
        if (!relatedSKUs || relatedSKUs.length === 0) {
            setProducts([])
            return;
        }
        query({
            variables: {
                condition: JSON.stringify({
                    filter: {sku: {eq: relatedSKUs}},
                    pageSize: relatedSKUs.length,
                    currentPage: 1,
                    sort: []
                })
            }
        });
    }, [relatedSKUs]);

    return {products, loading};
};

export default useRelatedProducts;
