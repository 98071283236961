/* eslint-disable no-underscore-dangle */
/* eslint-disable react/button-has-type */
/* eslint-disable camelcase */
import { T } from '@deity-io/falcon-i18n';
import PropTypes from 'prop-types';
import React, { memo, useState } from 'react';
import { useScrollPosition } from '../../../hooks/useScrollPosition';
import formatLocalePrice from '../../../utils/formatLocalePrice';
import AddToCartButton from '../AddToCartButton/AddToCartButton';
import ProductSidebarAddGroupedProduct from '../ProductSidebarAddGroupedProduct/ProductSidebarAddGroupedProduct';
import './ProductHeader.scss';
import Prices from '../../BaseCurrencyPrices/Prices';
import PriceBlock from "../PriceBlock/PriceBlock";

const AddToCart = ({ product }) => {
  const { is_on_order_only, days_to_delivery, is_saleable } = product;

  if (is_on_order_only && days_to_delivery === 0) {
    return (
      <button className="sidebar-add-product__unavailable" disabled>
        <T id="product.onlyInStore" />
      </button>
    );
  }

  if (!is_saleable) {
    return (
      <button className="sidebar-add-product__unavailableWeb" disabled>
        <T id="product.notWebshop" />
      </button>
    );
  }

  return <AddToCartButton sku={product.sku} quantity={1} name={product.name} className="product-header__button" />;
};

const ProductHeader = ({ product }) => {
  const [showOnScroll, setShowOnScroll] = useState(false);
  // const price = product.prices[0].amount;
  const price = 11;

  useScrollPosition(
    ({ currPos }) => {
      const isShow = currPos.y < -450;
      if (isShow !== showOnScroll) setShowOnScroll(isShow);
    },
    [showOnScroll],
  );

  return (
    <div className="product-header" hidden={!showOnScroll}>
      <h2 className="product-header__name">{product.name}</h2>
      <div className="product-header__side">
        <PriceBlock product={product} />

      </div>
    </div>
  );
};

ProductHeader.propTypes = {
  product: PropTypes.object,
};

export default memo(ProductHeader);
