import React, {useContext} from 'react';
import clsx from 'clsx';
import {I18nContext} from '@deity-io/falcon-i18n';
import {useQuery} from '@apollo/react-hooks';
import readConfig from '../../utils/readClientConfiguration';
import productionConfiguration from '../../../config/default.json';
import {BASE_CURRENCY} from '../../commonQueries/getBackendConfig';
import './Prices.scss';
import {priceNumberFormatter} from "../../utils/price";

const countryCode = readConfig(productionConfiguration, 'countryCode');
const isRs = countryCode.includes('RS');



const Prices = ({
  amount,
  isCategory,
  isProductPage,
  isDropdown,
  isSearch,
  breakRow,
  matrixMethod,
  isCartItem,
  isCms,
  pl30,
  isDash,
  noPad,
  isMinicart,
  pr5,
  isInfoPrice,
  isOldPrice,
  textNextToPrice,
  unitPerPeace,
}) => {
  const { t } = useContext(I18nContext);
  const baseCurrency = readConfig(productionConfiguration, 'currencyCode');

  const { data } = useQuery(BASE_CURRENCY);
  const { available_currency_codes: currencies, exchange_rates: rates } =
    (data && data.backendConfig && data.backendConfig.shop.currency) || {};

  if (!amount && amount !== 0) {
    return <></>;
  }

  const baseAmount = baseCurrency && priceNumberFormatter(amount, baseCurrency);

  if (isRs || (currencies && currencies.length <= 1)) {
    return (
      <span
        className={clsx(
            '_price_value',
            isCategory && !isRs && 'main-price__category',
            isProductPage && 'main-price__product',
            isDropdown && 'main-price__dropdown',
            isCartItem && 'main-price__cart-item',
            isMinicart && 'main-price__minicart-price',
            isInfoPrice && 'main-price__info-price',
            isOldPrice && 'main-price__old-price',
            isCms && 'pr-10',
            pl30 && 'main-price__pl30 pr-5 not-bold',
            pl30 && isProductPage && 'main-price__pl30__product not-bold pr-5',
            noPad && 'pr-0',
            pr5 && 'pr-5',
        )}
      >
        {baseAmount}
        {textNextToPrice || <></>}
        {unitPerPeace && `/${t('product.package')}`}
      </span>
    );
  }

  // NOTE: Calculating prices
  const calculatedAmounts =
    currencies &&
    currencies.map(currency => {
      return {
        price: rates.find(x => x.currency_to === currency).rate * amount,
        currency,
      };
    });
  const notBaseAmount = calculatedAmounts && calculatedAmounts.find(x => x.currency !== baseCurrency);
  const notBaseAmountValue = notBaseAmount && priceNumberFormatter(notBaseAmount.price, notBaseAmount.currency);

  return (
    <>
      <span
        className={clsx(
            '_price_value',
            'base-price',
            isCategory && 'main-price__category pr-5',
            isSearch && 'main-price__search pr-5',
            isProductPage && 'main-price__product pr-15',
            isDropdown && 'main-price__dropdown pr-5',
            isCartItem && 'main-price__cart-item',
            isMinicart && 'main-price__minicart-price pr-5',
            isInfoPrice && 'main-price__info-price',
            isOldPrice && 'main-price__old-price',
            isCms && 'pr-10',
            pl30 && 'main-price__pl30 pr-5 not-bold',
            pl30 && isProductPage && 'main-price__pl30__product not-bold pr-5',
            noPad && 'pr-0',
            pr5 && 'pr-5',
        )}
      >
        {baseAmount}
        {textNextToPrice || <></>}
        {unitPerPeace && `/${t('product.package')}`}
      </span>
      {(matrixMethod === 'matrixrate' || isDash) && ' / '}
      <span
        className={clsx(
            '_price_value',
            'not-base-price',
            !pl30 && 'not-base-color',
            isCategory && 'main-price__category__not-base',
            isProductPage && 'main-price__product__not-base',
            isDropdown && 'main-price__grouped',
            pl30 && 'main-price__pl30 not-bold',
            isCartItem && 'main-price__cart-item__not-base',
            isMinicart && 'main-price__minicart-price__not-base',
            isOldPrice && 'main-price__old-price__not-base',
            pl30 && isProductPage && 'main-price__pl30__product not-bold',
          noPad && 'pr-0',
        )}
      >
        ({notBaseAmountValue && notBaseAmountValue}
        {textNextToPrice || <></>}
        {unitPerPeace && `/${t('product.package')}`})
      </span>
    </>
  );
};

export default Prices;
