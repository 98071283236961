import React, {createContext, useContext, useState} from "react";

const Context = createContext();

export const useInspirationContext = () => {
    return useContext(Context);
};
const defaultPoint = {
    left: null,
    top: null,
    sku: null,
    disabled: true,
};
export const InsporationContextProvider = ({children, product}) => {

    const appendPoint = (item) => {
        let point = product.points_coordinates.find(_point => _point.sku == item.product.sku)
        if (point === undefined) {
            point = Object.assign({}, defaultPoint, {sku: item.product.sku})
        }

        const newItem = Object.assign({}, item,
            {
                point:
                    {
                        left: point.left,
                        top: point.top,
                        sku: point.sku,
                        disabled: Boolean(point.disabled)
                    }
            }
        )

        return newItem;
    }

    const [items, setItems] = useState(
        product.items.map((item) => {
            return appendPoint(item);
        })
    );

    const store = {
        items,
        setItems,
        product: product,
    }

    return <Context.Provider value={store}>{children}</Context.Provider>;
}