import { I18nContext, T } from '@deity-io/falcon-i18n';
import React, {useContext, useEffect, useState} from 'react';
import { useCartContext } from '../../../context/CartContext';
import { usePopup } from '../../../context/PopupContext';
import useMountState from '../../../hooks/useMountState';
import Button from '../../UI/Button/Button';
import { addToCartEvent } from '../../../hooks/useAnalytics';
import { useUserContext } from '../../../context/UserContext';
import { useMutation, useQuery } from '@apollo/react-hooks';
import {Link, useHistory} from 'react-router-dom';
import { BuildAbsoluteUrl } from "../../../utils/urlBuilder";

const STATE_NORMAL = 0;
const STATE_ADDING = 1;
const STATE_ADDED = 2;

const AddToCartButton = ({ sku, quantity, name, gallery, categories, selected, ...rest }) => {
  const { isMounted } = useMountState();
  const { handleProductAdd } = useCartContext();
  const [buttonState, setButtonState] = useState(STATE_NORMAL);
  const { t } = useContext(I18nContext);
  const { showAddToCartPopupMessage } = usePopup();
  const shouldBeDisabled = buttonState === STATE_ADDING || buttonState === STATE_ADDED;
  let productUrl = selected && typeof selected.canonical_url !== 'undefined' ? BuildAbsoluteUrl(selected.canonical_url) : null;
  productUrl = rest && typeof rest.product.canonical_url !== 'undefined' ? BuildAbsoluteUrl(rest.product.canonical_url) : null;



  const addProductToCart = () => {

    const subscribeToAlert = (uid) => {
      const encodedUID = atob(uid);
      const redirectPath = `/productalert/add/${encodedUID}${history.location.pathname}`;

      // Have to redirect here so that we get a clean history
      if (!user) {
        history.push(`/customer/login?referrer=${redirectPath}`);
        return;
      }
      history.push(redirectPath);
    };


    setButtonState(STATE_ADDING);
    handleProductAdd(sku, quantity).then(
      () => {
        if (isMounted()) {
          setButtonState(STATE_ADDED);

          setTimeout(() => {
            if (isMounted()) {
              setButtonState(STATE_NORMAL);
            }
          }, 1000);
          const message = t('messagePopup.addSuccess').replace('[product]', name);
          showAddToCartPopupMessage(message, 'success', 4000);
          if (selected) {
            addToCartEvent(selected.product, quantity, productUrl);
            selected.categories = categories;
            selected.gallery = gallery;
          } else {
            addToCartEvent(rest.product, quantity, productUrl);
          }
        }
      },
      err => {
        if (isMounted()) {
          if (err.message.includes('requested qty')) {
            showAddToCartPopupMessage(t('messagePopup.qtyError'), 'error', 4000);
          } else if(err.message.includes('0')) {
            showAddToCartPopupMessage(t('messagePopup.qtyZero'), 'error', 4000);
          } else {
            showAddToCartPopupMessage(err.message, 'error', 4000);
          }

          setButtonState(STATE_NORMAL);
        }
      },
    );
  };

  return (
    <Button
      trait="hover-submit"
      className="sidebar-add-product-submit"
      onClick={addProductToCart}
      disabled={shouldBeDisabled}
      {...rest}
    >
      {buttonState === STATE_NORMAL && <T id="product.addProduct" />}
      {buttonState === STATE_ADDING && <T id="product.addingProduct" />}
      {buttonState === STATE_ADDED && <T id="product.addedProduct" />}
    </Button>
  );
};

export default AddToCartButton;
