/* eslint-disable camelcase */
import {T} from '@deity-io/falcon-i18n';
// TODO: this is a security risk, should not be exposed on frontend
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, {memo, useContext, useMemo} from 'react';
import Select from 'react-select';
import dropdownIcon from '../../../assets/img/select_arrow_filled.png';
import {Context} from '../../../context/store';
import './ProductSidebarAddGroupedProduct.scss';
import PriceBlock from "../PriceBlock/PriceBlock";
const ProductSidebarProductNotAvailable = ({inHeader = false}) => {
    return (
        <div className={clsx('sidebar-empty-grouped', inHeader && 'sidebar-empty-grouped_header')}>
            <T id="product.groupedUnavailableMessage"/>
        </div>
    );
};

const ProductSidebarAddGroupedProduct = ({product, currentSku, onChangeProduct}) => {
    const {
        groupedProductQuantity: {quantity, setQuantity},
        groupedProductSelectValue: {select, setSelect},
        selectUrl,
        setSelectUrl,
    } = useContext(Context);

    const items = useMemo(() => {
        return product.items.filter(({product: prod}) => prod);
    }, [product]);

    const onChange = ({value}) => {
        onChangeProduct(value)
    };

    const getSelectOptions = useMemo(
        () =>
            items.map(({product: prod}, index) => ({
                value: prod.sku.toString(),
                label: `${prod.name} ${prod.sku}`,
                url: prod.canonical_url,
                customAbbreviation: index + 1,
                id: index,
                is_saleable: prod.is_saleable,
            })),
        [product],
    );

    const getSelectedOption = () => {
        return getSelectOptions.find((item) => item.value == currentSku)
    }

    const customStyles = {
        control: prev => ({
            ...prev,
            border: '1px solid #e1e1e1',
            padding: '5px 0 5px 12px',
            boxShadow: '0 1px 0 1px rgba(0, 0, 0, 0.04)',
            cursor: 'pointer',
        }),
        indicatorSeparator: () => ({
            width: 0,
        }),
        indicatorsContainer: () => ({
            width: 12,
            marginRight: 10,
        }),
        singleValue: prev => ({
            width: '92%',
            // '.custom-option__price': {
            //     display: 'none',
            // },
        }),
    };

    const DropdownIndicator = () => {
        return <img src={dropdownIcon} alt=""/>;
    };

    const formatOptionLabel = ({value, label, customAbbreviation, id, is_saleable}) => {

        const _product = product.items.find((item) => item.product.sku == value).product;
        const clasess = ['name'];
        if (!is_saleable) {
            clasess.push('custom-option__is-disabled')
        }
        let img = {
            small_image_url: 'https://s3.emmezeta.hr/media/catalog/product/placeholder/default/image.jpg'
        }
        if (_product.gallery.length > 0) {
            img = _product.gallery[0];
        }
        return (
            <div className="custom-option">
                <div className={'image-wrapper'}><img src={img.small_image_url}/></div>
                <div className='option-info-wrapper'>
                    <div className={clasess}>
                        {!is_saleable && (
                            <span><T id="product.unavailable"/></span>
                        )}{' '}
                        {_product.name} {_product.sku}
                    </div>
                    <div className='option-price-wrapper'>
                        <PriceBlock product={_product} hideLast30={true} hideLabels={true}/>
                    </div>
                </div>
            </div>
        );
    };
    return (
        <div className='sidebar-add-grouped-product'>
            <div
                className='sidebar-add-grouped-product__product-selection'
            >
                <div className="sidebar-add-grouped-product__select">
                    <Select
                        onChange={onChange}
                        styles={customStyles}
                        classNamePrefix="grouped-product-select"
                        options={getSelectOptions}
                        defaultValue={getSelectedOption()}
                        components={{DropdownIndicator}}
                        formatOptionLabel={formatOptionLabel}
                        instanceId="id"
                        theme={theme => ({
                            ...theme,
                            colors: {
                                ...theme.colors,
                                primary: '#fafafa',
                                primary50: '#fafafa',
                                primary25: '#fafafa',
                            },
                        })}
                    />
                </div>

            </div>
        </div>
    );
};

ProductSidebarAddGroupedProduct.propTypes = {
    currentSku: PropTypes.string,
    product: PropTypes.object,
    inHeader: PropTypes.bool,
};

export default memo(ProductSidebarAddGroupedProduct);
