import React from 'react';
import {FaMapMarkerAlt, FaTrash} from 'react-icons/fa';
import clsx from "clsx";

const AdminToolbar = ({item, removePoint, markerEnabled, setMarkerEnabled}) => {
    const {
        gallery
    } = item.product;

    let thumbnailImage = gallery.find(image => image.is_thumbnail === true);
    if(!thumbnailImage){
        thumbnailImage = gallery[0]
    }

    return (

        <div className={'toolBox'}>
            <img src={thumbnailImage.small_image_url}/>
            <div className={'icons'}>
                <span
                    className={
                        clsx({
                            'marker': true,
                            'active': markerEnabled,
                        })
                    }
                >
                    <FaMapMarkerAlt
                        size={25}
                        onClick={() => setMarkerEnabled()}
                        />
                    </span>
                <span>
                {!item.point.disabled && <FaTrash size={25} onClick={() => removePoint(item.product.sku)}/>}
                </span>
            </div>
        </div>
    )
}

export default AdminToolbar