import React, {useEffect, useRef, useState} from 'react';
import './ProductInspiration.scss'
import ItemView from "./ItemView";
import {AdminDecorator} from "./AdminDecorator";
import {useInspirationContext} from "./InspirationContext";

const Point = ({item, index, handleClick, handleMouseEnter2}) => {
    const [showPopup, setShowPopup]=useState(false)
    const pointStyle = {
        top: item.point.top,
        left: item.point.left,
        opacity: 1,
        cursor: 'pointer',
        position: 'absolute',
    };

    useEffect(() => {
        if (showPopup) {
            const handleClickOutside = (event) => {
                setShowPopup(false);
            };

            document.addEventListener('click', handleClickOutside);

            return () => {
                document.removeEventListener('click', handleClickOutside);
            };
        }
    }, [showPopup])

    const handleMouseEnter = (sku) => (event) => {
        setShowPopup(true)
    }

    return (
        <div key={index}
             style={pointStyle}
             className={`point ${item.point.active ? 'active' : ''}`}
             onClick={handleMouseEnter(item.product.sku)}
             onMouseEnter={handleMouseEnter(item.product.sku)}
             onMouseLeave={() => setShowPopup(false)}
        >
            <div className={'inner-point'}>
            {showPopup && <ItemView item={item}/>}
            </div>
        </div>
    )
}

const ProductInspiration = ({children, adminMode=false}) => {
    const {product, items} = useInspirationContext();
    const divRef = useRef(null);

    const findInspirationImage = (_product) => {
        let inspirationImage=_product.gallery.find((item) => {
            return item.is_inspiration;
        })
        if(!inspirationImage) {
            inspirationImage= _product.gallery[0]
        }
        return inspirationImage
    }


    const [showProduct, setShowProduct] = useState(null);
    const [popupStyles, setPopupStyles] = useState({});
    const [inspirationImage, setInspirationImage] = useState(null);

    useEffect(() => {
        setInspirationImage(findInspirationImage(product))
    }, [product])

    if (!inspirationImage) {
        return (
            <></>
        );
    }


    const handleMouseEnter = (sku) => (event) => {
        if(!adminMode) {
            if (showProduct != sku) {
                setShowProduct(sku)
                const rect = divRef.current.getBoundingClientRect();
                let x = event.clientX - rect.left;
                let y = event.clientY - rect.top;
                if (x > (rect.width / 2)) {
                    x = x - 300
                } else {
                    x = x + 30
                }
                if (y > (rect.height / 2)) {
                    y = y - 300
                } else {
                    y = y - 200
                }
                x = Math.max(x, 0)
                y = Math.max(y, 0)
                setPopupStyles({
                    left: `${x}px`,
                    top: `${y}px`,
                })
            }
        }
    }

    const handleDivClick = (event) => {
        const rect = divRef.current.getBoundingClientRect();
        let x = event.clientX - rect.left;
        let y = event.clientY - rect.top;

        let width = rect.width;
        let height = rect.height;

        const xPercent = parseInt((x * 100 / width), 0);
        const yPercent = parseInt((y * 100 / height), 0);

        const setPointEvent = new CustomEvent('setPointEvent', {
            detail: {x: xPercent + '%', y: yPercent + '%'},
        });
        document.dispatchEvent(setPointEvent);
        // setMarker({x: xPercent+'%', y: yPercent+'%'})
    };

    const setActivePoint = (sku) => {
    }
    return (
        <>
            {children}
            <div className='inspiration-image'
                 ref={divRef}
                 onClick={handleDivClick}

            >
                <img src={inspirationImage.url}
                     style={{
                         position: 'relative',
                         maxWidth: '100%',
                         height: 'auto',
                         display: 'block',
                         cursor: 'pointer'
                     }}
                />
                <div className={'points'}>
                    {
                        items.filter(item => {
                            return !item.point.disabled
                        }).map((item, index) => {
                                return (
                                    <Point key={index} item={{...item, index}}
                                           handleMouseEnter={handleMouseEnter}
                                           handleClick={setActivePoint}/>
                                )
                            }
                        )
                    }
                </div>

                {showProduct && <ItemView item={product.items.find((item) => {
                    return item.product.sku == showProduct
                })} style={popupStyles}/>}
            </div>

        </>
    )
}

export default AdminDecorator(ProductInspiration);
