import { useEffect } from 'react';

export const useSamitoReset = (pathname, loading) => {
  useEffect(() => {
    if (!loading && typeof window !== 'undefined' && window.SavecartNS) {
      if(window.SavecartNS.stop){
        window.SavecartNS.stop();
        window.SavecartNS.start();
      }
    }
  }, [pathname, loading]);
  return null;
};

export const useSamitoScript = (samito, isProduction, loading) => {
  useEffect(() => {
    if (!loading && samito && samito.samito_enable === '1') {
      const script = isProduction ? samito.samito_production : samito.samito_feature;

      const scriptelem = document.createElement('script');
      scriptelem.innerHTML = script.match(/<script>([\s\S]+)<\/script>/)[1];
      document.body.appendChild(scriptelem);
    }
  }, [!samito, loading]);
  return null;
};
